import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Card } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React from "react";

import { ListagemOnline } from "../../types";

interface MuiTableProps {
  items: ListagemOnline[];
  isLoading: boolean;
}

export const TableOnline: React.FC<MuiTableProps> = ({ items, isLoading }) => {
  const columns: GridColDef[] = [
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {params.value === "Online" && (
            <FiberManualRecordIcon
              style={{ color: "green", marginRight: 4, fontSize: "1rem" }}
            />
          )}
          {params.value === "Offline" && (
            <FiberManualRecordIcon
              style={{ color: "red", marginRight: 4, fontSize: "1rem" }}
            />
          )}
          {params.value}
        </div>
      ),
    },
    { field: "nome", headerName: "Nome", width: 130 },
    { field: "usuario", headerName: "Usuário", width: 130 },
    { field: "sessoes", headerName: "Sessões", width: 60 },
    { field: "cadastradoPor", headerName: "Cadastrado Por", width: 140 },
    { field: "conectadoEm", headerName: "Conectado em", width: 180 },
    { field: "ip", headerName: "IP", width: 140 },
    { field: "perfil", headerName: "Perfil", width: 110 },
    { field: "canal", headerName: "Canal", width: 200 },
    { field: "onlinePor", headerName: "Online Por", width: 150 },
  ];

  return (
    <Card>
      <div style={{ height: 550, width: "100%" }}>
        <DataGrid
          rows={items}
          columns={columns}
          pagination
          loading={isLoading}
        />
      </div>
    </Card>
  );
};
