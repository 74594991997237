import { Card } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React from "react";

import { ListagemFalhado } from "../../types";

interface MuiTableProps {
  items: ListagemFalhado[];
  isLoading: boolean;
}

export const TableFalhado: React.FC<MuiTableProps> = ({ items, isLoading }) => {
  const columns: GridColDef[] = [
    { field: "nome", headerName: "Nome", width: 130 },
    { field: "contexto", headerName: "Contexto", width: 150 },
    { field: "primeiraFalha", headerName: "Primeira Falha", width: 200 },
    { field: "ultimaFalha", headerName: "Última Falha", width: 200 },
    { field: "contadorFalhas", headerName: "T. Falhas", width: 120 },
    { field: "ip", headerName: "IP", width: 120 },
    { field: "motivo", headerName: "Motivo", width: 140 },
  ];

  return (
    <Card>
      <div style={{ height: 550, width: "100%" }}>
        <DataGrid
          rows={items}
          columns={columns}
          pagination
          loading={isLoading}
        />
      </div>
    </Card>
  );
};
