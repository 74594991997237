import { Box, Container, Stack, Typography } from "@mui/material";
import { FC, useState, useEffect } from "react";
import { toast } from "react-toastify";

import { Layout } from "../../components/layout";
import { Search } from "../../components/search";
import useAuth from "../../hooks/useAuth";
import { recuperarFalhado } from "../../services/status";
import { ListagemFalhado } from "../../types";
import { TableFalhado } from "./tableFalhado";

const HomeFalhado: FC = () => {
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [usuariosFalhados, setUsuariosFalhados] = useState<ListagemFalhado[]>(
    [],
  );

  const fetchUsuariosFalhados = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await recuperarFalhado(id);
      if (response) {
        const dadosComId = response.data.map((item, index) => ({
          ...item,
          id: `id-${index}`,
        }));
        setUsuariosFalhados(dadosComId);
      }
    } catch (error) {
      toast.error("Erro ao recuperar todos os usuários:" + error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user?.idUsuario) {
      fetchUsuariosFalhados(user.idUsuario);
    }
  }, [user]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = usuariosFalhados.filter((item) =>
    item.nome.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <Layout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 3,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Falhado</Typography>
              </Stack>
            </Stack>
            <Search onChange={handleSearchChange} />
            <TableFalhado items={filteredData} isLoading={isLoading} />
          </Stack>
        </Container>
      </Box>
    </Layout>
  );
};

export default HomeFalhado;
