import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import {
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
  SxProps,
  Theme,
} from "@mui/material";
import { ReactNode } from "react";

interface OverviewTotalCustomersProps {
  title: string;
  bgColor: string;
  difference?: number;
  positive?: boolean;
  value: ReactNode;
  sx?: SxProps<Theme>;
}

export const OverviewTotalCustomers = ({
  sx,
  value,
  title,
  bgColor,
}: OverviewTotalCustomersProps) => {
  return (
    <Card sx={sx}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              {title}
            </Typography>
            <Typography variant="h4">{value}</Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: bgColor,
              height: 56,
              width: 56,
            }}
          >
            <SvgIcon>
              <UsersIcon />
            </SvgIcon>
          </Avatar>
        </Stack>
      </CardContent>
    </Card>
  );
};
