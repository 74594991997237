import { createTheme as createMuiTheme, Theme } from "@mui/material";
import { ptBR as corePtBR } from "@mui/material/locale";
import { ptBR } from "@mui/x-data-grid/locales";
import { ptBR as pickersPtBR } from "@mui/x-date-pickers/locales";

import { createComponents } from "./create-components";
import { createPalette } from "./create-palette";
import { createShadows } from "./create-shadows";
import { createTypography } from "./create-typography";

export function createTheme(): Theme {
  const palette = createPalette();
  const components = createComponents({ palette });
  const shadows = createShadows();
  const typography = createTypography();

  return createMuiTheme(
    {
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1440,
        },
      },
      components,
      palette,
      shadows,
      shape: {
        borderRadius: 8,
      },
      typography,
    },
    ptBR,
    pickersPtBR,
    corePtBR,
  );
}
