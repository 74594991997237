import type { ListagemPerfil, AxiosResponse } from "../../types";
import { painelApi } from "../painelApi";

const PATH_V1_PERFIS = "/v1/perfis";

export async function recuperarTodosPerfis(): Promise<
  AxiosResponse<ListagemPerfil[]>
> {
  return await painelApi.get(PATH_V1_PERFIS);
}
