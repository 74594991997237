import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Card } from "@mui/material";
import { DataGrid, GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import React from "react";
import { useNavigate } from "react-router-dom";

import { ListagemCsp } from "../../types";

interface MuiTableProps {
  items: ListagemCsp[];
  isLoading: boolean;
  onDelete: (id: string) => Promise<void>;
}

export const TableCsp: React.FC<MuiTableProps> = ({
  items,
  onDelete,
  isLoading,
}) => {
  const navigate = useNavigate();

  const handleEditClick = (rowData: ListagemCsp) => {
    navigate(`/csp/${rowData.id}`);
  };

  const columns: GridColDef[] = [
    { field: "nome", headerName: "Nome", width: 150 },
    { field: "url", headerName: "URL", width: 150 },
    { field: "porta", headerName: "Porta", width: 110 },
    { field: "usuario", headerName: "Usuário", width: 125 },
    { field: "senha", headerName: "Senha", width: 150 },
    { field: "protocolo", headerName: "Protocolo", width: 110 },
    { field: "bloqueado", headerName: "Bloqueado", width: 110 },
    {
      field: "acoes",
      type: "actions",
      headerName: "Opções",
      width: 100,
      renderCell: (params) => {
        return [
          <GridActionsCellItem
            key={`${params.row.id}-edit`}
            icon={<EditIcon />}
            label="Editar"
            className="textPrimary"
            onClick={() => handleEditClick(params.row)}
            color="inherit"
          />,
          <GridActionsCellItem
            key={`${params.row.id}-delete`}
            icon={<DeleteIcon />}
            label="Excluir"
            onClick={() => onDelete(params.id.toString())}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Card>
      <div style={{ height: 550, width: "100%" }}>
        <DataGrid
          rows={items}
          columns={columns}
          pagination
          loading={isLoading}
        />
      </div>
    </Card>
  );
};
