import { Box, Container, Stack, Typography } from "@mui/material";
import { FC, useState, useEffect } from "react";
import { toast } from "react-toastify";

import { Layout } from "../../components/layout";
import { Search } from "../../components/search";
import useAuth from "../../hooks/useAuth";
import { recuperarAtivosPorConexao } from "../../services/relatorio";
import { recuperarHierarquiaPorId } from "../../services/usuario";
import { AtivoConexao, HierarquiaUsuario } from "../../types";
import { TableRelatorio } from "./tableRelatorio";

const HomeRelatorio: FC = () => {
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [usuariosOnline, setUsuariosOnline] = useState<AtivoConexao[]>([]);
  const [hierarquias, setHierarquias] = useState<HierarquiaUsuario[]>([]);

  const fetchAtivosConexao = async (id: string, detalhado: boolean) => {
    try {
      setIsLoading(true);
      const response = await recuperarAtivosPorConexao(id, detalhado);
      if (response) {
        const dadosComId = response.data.map((item, index) => ({
          ...item,
          id: `id-${index}`,
        }));
        setUsuariosOnline(dadosComId);
      }
    } catch (error) {
      toast.error("Erro ao recuperar todos os usuários:" + error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchHierarquia = async (
    userId: string | undefined,
    tipoUsuario: string,
  ) => {
    if (!userId) {
      toast.info("ID do usuário não fornecido para fetchHierarquia.");
      return;
    }

    try {
      const responseHierarquia = await recuperarHierarquiaPorId(
        userId,
        tipoUsuario,
      );
      if (responseHierarquia) {
        setHierarquias(responseHierarquia.data);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
      } else {
        toast.error("Erro ao recuperar hierarquia:" + error);
      }
    }
  };

  useEffect(() => {
    if (user?.idUsuario) {
      fetchAtivosConexao(user.idUsuario, false);
      fetchHierarquia(user.idUsuario, user.tipo);
    }
  }, [user]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = usuariosOnline.filter(
    (item) =>
      item.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.usuario.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleHierarquiaChange = async (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const selectedValue = event.target.value as string;
    let userIdToUse: string | null = null;
    let detalhado: boolean | null = null;

    if (selectedValue === "0") {
      userIdToUse = user?.idUsuario || null;
      detalhado = false;
    } else {
      userIdToUse = selectedValue;
      detalhado = true;
    }

    if (userIdToUse) {
      await fetchAtivosConexao(userIdToUse, detalhado);
    } else {
      toast.info("Nenhum ID de usuário disponível para buscar hierarquia.");
    }
  };

  return (
    <Layout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 3,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Relatório</Typography>
              </Stack>
            </Stack>
            <Search
              onChange={handleSearchChange}
              hierarquias={hierarquias}
              onHierarquiaChange={handleHierarquiaChange}
            />
            <TableRelatorio items={filteredData} isLoading={isLoading} />
          </Stack>
        </Container>
      </Box>
    </Layout>
  );
};

export default HomeRelatorio;
