import CommandLineIcon from "@heroicons/react/24/solid/CommandLineIcon";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Divider,
} from "@mui/material";
import { FC, useState, ChangeEvent, FormEvent } from "react";
import { toast } from "react-toastify";

import { Layout } from "../../components/layout";
import { uploadArquivo } from "../../services/importar";
import { executarRotina } from "../../services/task";
import type { Response, AxiosResponse } from "../../types";

const HomeImportar: FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [responseText, setResponseText] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingRotina, setLoadingRotina] = useState<boolean>(false);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    } else {
      setFile(null);
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (file) {
      setLoading(true);
      try {
        const response: AxiosResponse<Response> = await uploadArquivo(file);
        setResponseText(response.data.response);
        toast.success("Arquivo importado com sucesso!");
      } catch (error: any) {
        let errorMessage = "";
        if (
          error.response &&
          error.response.data &&
          error.response.data.response
        ) {
          errorMessage += error.response.data.response;
        } else {
          errorMessage += error.message;
        }
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Nenhum arquivo selecionado.");
    }
  };

  const handleRotinaDiaria = async () => {
    setLoadingRotina(true);
    try {
      const response: AxiosResponse<Response> = await executarRotina();
      toast.success(response.data.response);
    } catch (error: any) {
      let errorMessage = "";
      if (
        error.response &&
        error.response.data &&
        error.response.data.response
      ) {
        errorMessage += error.response.data.response;
      } else {
        errorMessage += error.message;
      }
      toast.error(errorMessage);
    } finally {
      setLoadingRotina(false);
    }
  };

  return (
    <Layout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4">Importar arquivo .sql</Typography>
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit}>
                <input type="file" onChange={handleFileChange} />
                {responseText && (
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    {responseText}
                  </Typography>
                )}
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size={24} /> : "Upload"}
                  </Button>
                </Box>
              </form>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mt: 3, mb: 3, borderBottomWidth: 3 }} />
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleRotinaDiaria}
                  disabled={loadingRotina}
                  startIcon={<CommandLineIcon width={24} height={24} />}
                >
                  {loadingRotina ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Executar rotina diária"
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Layout>
  );
};

export default HomeImportar;
