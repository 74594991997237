import { CircularProgress, Box } from "@mui/material";
import React, { FC, ReactElement } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import useAuth from "../hooks/useAuth";
import HomeBackup from "../pages/backup/homeBackup";
import ConfigCsp from "../pages/csp/configCsp";
import TelaCsp from "../pages/csp/csp";
import HomeCsp from "../pages/csp/homeCsp";
import HomeDesconectado from "../pages/desconectado/homeDesconectado";
import HomeExportar from "../pages/exportar/homeExportar";
import HomeFalhado from "../pages/falhado/homeFalhado";
import HomeAdmin from "../pages/home/homeAdmin";
import HomeImportar from "../pages/importar/homeImportar";
import HomeOnline from "../pages/online/homeOnline";
import HomeRelatorio from "../pages/relatorio/homeRelatorio";
import HomeReshare from "../pages/reshare/homeReshare";
import HomeRevenda from "../pages/revenda/homeRevenda";
import Signin from "../pages/signIn";
import HomeStatusLogs from "../pages/statusLogs/homeStatusLogs";
import HomeTeste from "../pages/teste/homeTeste";
import TelaEditarUsuario from "../pages/usuario/editarUsuario";
import HomeUsuario from "../pages/usuario/homeUsuario";
import TelaUsuario from "../pages/usuario/usuario";
import TelaUsuarioRapido from "../pages/usuario/usuarioRapido";

interface PrivateProps {
  Item: FC;
}

const AdminPrivate: FC<PrivateProps> = ({ Item }): ReactElement => {
  const { signed, user, loading } = useAuth();

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return signed && user?.tipo === "ADMIN" ? <Item /> : <Navigate to="/" />;
};

const RevendaPrivate: FC<PrivateProps> = ({ Item }): ReactElement => {
  const { signed, user, loading } = useAuth();

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return signed && user?.tipo === "REVENDA" ? <Item /> : <Navigate to="/" />;
};

const PrivateRoute: FC<PrivateProps> = ({ Item }): ReactElement => {
  const { signed, loading } = useAuth();

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return signed ? <Item /> : <Navigate to="/" />;
};

const RoutesApp: FC = (): ReactElement => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route
          path="/adminPrivate"
          element={<AdminPrivate Item={HomeAdmin} />}
        />
        <Route path="/home" element={<PrivateRoute Item={HomeAdmin} />} />
        <Route path="/revenda" element={<PrivateRoute Item={HomeRevenda} />} />
        <Route path="/usuario" element={<PrivateRoute Item={HomeUsuario} />} />
        <Route path="/teste" element={<PrivateRoute Item={HomeTeste} />} />
        <Route path="/csps" element={<AdminPrivate Item={HomeCsp} />} />
        <Route path="/csp" element={<AdminPrivate Item={TelaCsp} />} />
        <Route path="/csp/:id" element={<AdminPrivate Item={TelaCsp} />} />
        <Route path="/configcsp" element={<AdminPrivate Item={ConfigCsp} />} />
        <Route
          path="/importar"
          element={<AdminPrivate Item={HomeImportar} />}
        />
        <Route
          path="/exportar"
          element={<AdminPrivate Item={HomeExportar} />}
        />
        <Route path="/backup" element={<AdminPrivate Item={HomeBackup} />} />
        <Route path="/online" element={<PrivateRoute Item={HomeOnline} />} />
        <Route
          path="/desconectado"
          element={<PrivateRoute Item={HomeDesconectado} />}
        />
        <Route path="/falhado" element={<PrivateRoute Item={HomeFalhado} />} />
        <Route path="/logs" element={<PrivateRoute Item={HomeStatusLogs} />} />
        <Route path="/reshare" element={<PrivateRoute Item={HomeReshare} />} />
        <Route
          path="/relatorios"
          element={<PrivateRoute Item={HomeRelatorio} />}
        />
        <Route
          path="/usuario/editar"
          element={<PrivateRoute Item={TelaUsuario} />}
        />
        <Route
          path="/usuario/editarRapido"
          element={<PrivateRoute Item={TelaUsuarioRapido} />}
        />
        <Route
          path="/usuario/editarUsuario/:id"
          element={<PrivateRoute Item={TelaEditarUsuario} />}
        />
        <Route
          path="/revendaPrivateExample"
          element={<RevendaPrivate Item={HomeRevenda} />}
        />
        <Route path="*" element={<Signin />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesApp;
