import UAParser from "ua-parser-js";

import { ClientInfo } from "../../types";

export const fetchClientIp = async (): Promise<string> => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Erro ao obter o endereço IP:", error);
    return "IP desconhecido";
  }
};

export const getClientInfo = async (): Promise<ClientInfo> => {
  const clientIp = await fetchClientIp();
  const parser = new UAParser();
  const result = parser.getResult();

  return {
    ip: clientIp,
    navegador: result.browser.name,
    versao: result.browser.version,
    plataforma: result.os.name,
  };
};
