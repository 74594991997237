import type { AxiosResponse, AtivoConexao } from "../../types";
import { painelApi } from "../painelApi";

const PATH_V1_RELATORIO = "/v1/relatorio";

export async function recuperarAtivosPorConexao(
  codigo: string,
  detalhado: boolean,
): Promise<AxiosResponse<AtivoConexao[]>> {
  return await painelApi.get(`${PATH_V1_RELATORIO}/${codigo}`, {
    params: { detalhado },
  });
}
