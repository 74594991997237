import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import React, { FC } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AuthProvider } from "./contexts/auth";
import RoutesApp from "./routes";
import { createTheme } from "./theme";

const theme = createTheme();

const App: FC = () => (
  <AuthProvider>
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <CssBaseline />
      <RoutesApp />
    </ThemeProvider>
  </AuthProvider>
);

export default App;
