import axios from "axios";

import { getApiBaseUrl } from "./baseUrl";

export const painelApi = axios.create({
  baseURL: getApiBaseUrl(),
  headers: {
    "Content-Type": "application/json",
  },
});

painelApi.interceptors.request.use(
  (config) => {
    const userToken = sessionStorage.getItem("user_token");
    if (userToken) {
      config.headers.Authorization = `Bearer ${userToken.replace(/"/g, "")}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
