import type { AxiosResponse } from "../../types";
import { painelApi } from "../painelApi";

const PATH_V1_BACKUP = "/v1/backup";

function formatDate(date: Date): string {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

function generateFileName(): string {
  const today = new Date();
  const formattedDate = formatDate(today);
  return `backup_${formattedDate}.zip`;
}

export async function downloadBackup(): Promise<{
  fileName: string;
  data: Blob;
}> {
  const response = await painelApi.get(`/v1/backup/download`, {
    responseType: "blob",
  });

  const fileName = generateFileName();

  return {
    fileName,
    data: response.data,
  };
}
