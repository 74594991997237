import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import React, { FC, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Layout } from "../../components/layout";
import {
  recuperarPainelConfiguracoes,
  atualizarPainelConfiguracoes,
} from "../../services/painelConfiguracoes";
import { PainelConfiguracoes, AxiosResponse } from "../../types";

const ConfigCsp: FC = () => {
  const navigate = useNavigate();
  const [painelConfiguracoes, setPainelConfiguracoes] =
    useState<PainelConfiguracoes>({
      id: null,
      senha: "",
      deskeys: "",
      bloquearIp: "",
      ip: "",
    });

  useEffect(() => {
    Promise.all([
      recuperarPainelConfiguracoes().then(
        (response: AxiosResponse<PainelConfiguracoes>) => {
          if (response) {
            setPainelConfiguracoes(response.data);
          }
        },
      ),
    ]);
  }, []);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      atualizarPainelConfiguracoes(painelConfiguracoes).then((response) => {
        if (response) {
          toast.success("Configuração atualizada com sucesso!");
          navigate(-1);
        }
      });
    },
    [painelConfiguracoes],
  );

  const handleCancelar = () => {
    navigate(-1);
  };

  return (
    <Layout>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card>
          <CardHeader
            subheader="Essas informações podem ser editadas"
            title={"Editar"}
          />
          <CardContent sx={{ pt: 0 }}>
            <Box sx={{ m: -1.5 }}>
              <Grid container spacing={3}>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Senha"
                    name="senha"
                    required
                    value={painelConfiguracoes?.senha}
                    onChange={(event) =>
                      setPainelConfiguracoes({
                        ...painelConfiguracoes,
                        senha: event.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Deskeys"
                    name="deskeys"
                    required
                    value={painelConfiguracoes?.deskeys}
                    onChange={(event) =>
                      setPainelConfiguracoes({
                        ...painelConfiguracoes,
                        deskeys: event.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Bloquear Ip"
                    name="bloquearip"
                    required
                    value={painelConfiguracoes?.bloquearIp}
                    onChange={(event) =>
                      setPainelConfiguracoes({
                        ...painelConfiguracoes,
                        bloquearIp: event.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="ip"
                    name="ip"
                    required
                    value={painelConfiguracoes?.ip}
                    onChange={(event) =>
                      setPainelConfiguracoes({
                        ...painelConfiguracoes,
                        ip: event.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button type="submit" variant="contained">
              Salvar
            </Button>
            <Button type="button" variant="outlined" onClick={handleCancelar}>
              Cancelar
            </Button>
          </CardActions>
        </Card>
      </form>
    </Layout>
  );
};

export default ConfigCsp;
