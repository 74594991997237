import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Card } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React from "react";

import { AtivoConexao } from "../../types";

interface MuiTableProps {
  items: AtivoConexao[];
  isLoading: boolean;
}

export const TableRelatorio: React.FC<MuiTableProps> = ({
  items,
  isLoading,
}) => {
  const columns: GridColDef[] = [
    {
      field: "perfilNome",
      headerName: "Perfil",
      width: 100,
    },
    { field: "cadastradoPor", headerName: "Cadastrado Por", width: 130 },
    { field: "nome", headerName: "Nome", width: 130 },
    { field: "usuario", headerName: "Usuário", width: 130 },
    {
      field: "ativosConexaoVencimento",
      headerName: "Ativos por Conexão e Vencimento",
      width: 250,
    },
  ];

  return (
    <Card>
      <div style={{ height: 550, width: "100%" }}>
        <DataGrid
          rows={items}
          columns={columns}
          pagination
          loading={isLoading}
        />
      </div>
    </Card>
  );
};
