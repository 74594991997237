import type {
  ListagemOnline,
  ListagemDesconectado,
  ListagemFalhado,
  ListagemStatusLogs,
  ListagemReshare,
  ServidorStatus,
  AxiosResponse,
} from "../../types";
import { painelApi } from "../painelApi";

const PATH_V1_STATUS = "/v1/status";

export async function recuperarOnline(
  codigo: string,
): Promise<AxiosResponse<ListagemOnline[]>> {
  return await painelApi.get(`${PATH_V1_STATUS}/online/${codigo}`);
}

export async function recuperarDesconectado(
  codigo: string,
): Promise<AxiosResponse<ListagemDesconectado[]>> {
  return await painelApi.get(`${PATH_V1_STATUS}/desconectado/${codigo}`);
}

export async function recuperarFalhado(
  codigo: string,
): Promise<AxiosResponse<ListagemFalhado[]>> {
  return await painelApi.get(`${PATH_V1_STATUS}/falhado/${codigo}`);
}

export async function recuperarStatusLogs(
  codigo: string,
): Promise<AxiosResponse<ListagemStatusLogs[]>> {
  return await painelApi.get(`${PATH_V1_STATUS}/status-logs/${codigo}`);
}

export async function recuperarReshare(
  codigo: string,
): Promise<AxiosResponse<ListagemReshare[]>> {
  return await painelApi.get(`${PATH_V1_STATUS}/status-reshare/${codigo}`);
}

export async function recuperarServidorStatus(
  codigo: string,
): Promise<AxiosResponse<ServidorStatus[]>> {
  return await painelApi.get(`${PATH_V1_STATUS}/servidor-status/${codigo}`);
}
