import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Card } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React from "react";

import { ListagemStatusLogs } from "../../types";

interface MuiTableProps {
  items: ListagemStatusLogs[];
  isLoading: boolean;
}

export const TableStatusLogs: React.FC<MuiTableProps> = ({
  items,
  isLoading,
}) => {
  const columns: GridColDef[] = [
    {
      field: "ativo",
      headerName: "Status",
      width: 75,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {params.value === "Online" && (
            <FiberManualRecordIcon
              style={{ color: "green", marginRight: 4, fontSize: "1rem" }}
            />
          )}
          {params.value === "Offline" && (
            <FiberManualRecordIcon
              style={{ color: "red", marginRight: 4, fontSize: "1rem" }}
            />
          )}
          {params.value}
        </div>
      ),
    },
    { field: "nomeExibicao", headerName: "Nome", width: 130 },
    { field: "nome", headerName: "Usuário", width: 130 },
    { field: "contagemEcm", headerName: "ECM", width: 100 },
    { field: "intervaloEcmMedio", headerName: "IV", width: 100 },
    { field: "flags", headerName: "Flags", width: 120 },
    { field: "ip", headerName: "IP", width: 120 },
    { field: "perfil", headerName: "Perfil", width: 120 },
    { field: "nomeServico", headerName: "Canal", width: 180 },
  ];

  return (
    <Card>
      <div style={{ height: 550, width: "100%" }}>
        <DataGrid
          rows={items}
          columns={columns}
          pagination
          loading={isLoading}
        />
      </div>
    </Card>
  );
};
