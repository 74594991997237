import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, TextField, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { getApiBaseUrl } from "../services/baseUrl";
import { recuperarListaXML } from "../services/home";
import type { Response, AxiosResponse } from "../types";

const CopyableTextField: React.FC = () => {
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    Promise.all([
      recuperarListaXML().then((response: AxiosResponse<Response>) => {
        if (response) {
          setValue(getApiBaseUrl() + response.data.response);
        }
      }),
    ]);
  }, []);

  const handleCopy = async () => {
    if (value) {
      try {
        await navigator.clipboard.writeText(value);
        toast.success("URL copiada para a área de transferência!");
      } catch (error) {
        toast.error("Erro ao copiar URL. " + error);
      }
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <TextField
        value={value}
        variant="outlined"
        fullWidth
        InputProps={{
          readOnly: true,
        }}
        sx={{ mr: 1 }}
      />
      <IconButton onClick={handleCopy} color="primary" aria-label="copy">
        <ContentCopyIcon />
      </IconButton>
    </Box>
  );
};

export default CopyableTextField;
