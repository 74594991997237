import type { PainelConfiguracoes, AxiosResponse } from "../../types";
import { painelApi } from "../painelApi";

const PATH_V1_PAINEL_CONFIGURACOES = "/v1/painel-configuracoes";

export async function recuperarPainelConfiguracoes(): Promise<
  AxiosResponse<PainelConfiguracoes>
> {
  return await painelApi.get(PATH_V1_PAINEL_CONFIGURACOES);
}

export async function atualizarPainelConfiguracoes(
  request: PainelConfiguracoes,
): Promise<AxiosResponse<PainelConfiguracoes>> {
  return await painelApi.put(`${PATH_V1_PAINEL_CONFIGURACOES}`, request);
}
