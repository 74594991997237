import { Card } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React from "react";

import { ListagemReshare } from "../../types";

interface MuiTableProps {
  items: ListagemReshare[];
  isLoading: boolean;
}

export const TableReshare: React.FC<MuiTableProps> = ({ items, isLoading }) => {
  const columns: GridColDef[] = [
    { field: "nomeExibicao", headerName: "Nome", width: 130 },
    { field: "nome", headerName: "Usuário", width: 130 },
    { field: "perfil", headerName: "Perfil", width: 120 },
    { field: "host", headerName: "IP", width: 120 },
    { field: "conectadoEm", headerName: "Conectado Em", width: 180 },
    { field: "duracao", headerName: "Tempo Conectado", width: 120 },
    { field: "flags", headerName: "Flags", width: 120 },
    { field: "nomeServico", headerName: "Canal", width: 180 },
  ];

  return (
    <Card>
      <div style={{ height: 550, width: "100%" }}>
        <DataGrid
          rows={items}
          columns={columns}
          pagination
          loading={isLoading}
        />
      </div>
    </Card>
  );
};
