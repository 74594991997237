import { common } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";

import { error, indigo, info, neutral, success, warning } from "./colors";

interface ColorType {
  main: string;
  lightest?: string;
  light?: string;
  dark?: string;
  darkest?: string;
  contrastText?: string;
  alpha4?: string;
  alpha8?: string;
  alpha12?: string;
  alpha30?: string;
  alpha50?: string;
}

// Definindo a interface para a paleta de cores
interface Palette {
  action: {
    active: string;
    disabled: string;
    disabledBackground: string;
    focus: string;
    hover: string;
    selected: string;
  };
  background: {
    default: string;
    paper: string;
  };
  divider: string;
  error: ColorType;
  info: ColorType;
  mode: "light" | "dark";
  neutral: { [key: string]: string };
  primary: ColorType;
  success: ColorType;
  text: {
    primary: string;
    secondary: string;
    disabled: string;
  };
  warning: ColorType;
}

export function createPalette(): Palette {
  return {
    action: {
      active: neutral[500],
      disabled: alpha(neutral[900], 0.38),
      disabledBackground: alpha(neutral[900], 0.12),
      focus: alpha(neutral[900], 0.16),
      hover: alpha(neutral[900], 0.04),
      selected: alpha(neutral[900], 0.12),
    },
    background: {
      default: common.white,
      paper: common.white,
    },
    divider: "#F2F4F7",
    error,
    info,
    mode: "light",
    neutral,
    primary: indigo,
    success,
    text: {
      primary: neutral[900],
      secondary: neutral[500],
      disabled: alpha(neutral[900], 0.38),
    },
    warning,
  };
}
