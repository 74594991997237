import type { ListagemCsp, Painel, AxiosResponse } from "../../types";
import { painelApi } from "../painelApi";

const PATH_V1_PAINEL = "/v1/painel";

export async function recuperarTodosPaineis(): Promise<
  AxiosResponse<ListagemCsp[]>
> {
  return await painelApi.get(PATH_V1_PAINEL);
}

export async function salvarPainel(
  request: Painel,
): Promise<AxiosResponse<Painel>> {
  return await painelApi.post(PATH_V1_PAINEL, request);
}

export async function recuperarPainelPorId(
  codigo: string,
): Promise<AxiosResponse<Painel>> {
  return await painelApi.get(`${PATH_V1_PAINEL}/${codigo}`);
}

export async function atualizarPainelPorId(
  codigo: string,
  request: Painel,
): Promise<AxiosResponse<Painel>> {
  return await painelApi.put(`${PATH_V1_PAINEL}/${codigo}`, request);
}

export async function deletarPainelPorId(
  codigo: string,
): Promise<AxiosResponse<Response>> {
  return await painelApi.delete(`${PATH_V1_PAINEL}/${codigo}`);
}
