import CogIcon from "@heroicons/react/24/solid/CogIcon";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import {
  Box,
  Container,
  Stack,
  Typography,
  Button,
  SvgIcon,
} from "@mui/material";
import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Layout } from "../../components/layout";
import { Search } from "../../components/search";
import useAuth from "../../hooks/useAuth";
import { recuperarTodosPaineis, deletarPainelPorId } from "../../services/csp";
import { ListagemCsp, AxiosResponse } from "../../types";
import { TableCsp } from "./tableCsp";

const HomeCsp: FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [csps, setCsps] = useState<ListagemCsp[]>([]);

  const fetchCsps = async () => {
    try {
      setIsLoading(true);
      const response = await recuperarTodosPaineis();
      if (response) {
        setCsps(response.data);
      }
    } catch (error) {
      toast.error("Erro ao recuperar todos os csps:" + error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user?.idUsuario) {
      fetchCsps();
    }
  }, [user]);

  const handleAdd = () => {
    navigate(`/csp`);
  };

  const handleConfig = () => {
    navigate(`/configcsp`);
  };

  const handleDelete = async (id: string) => {
    try {
      Promise.all([
        deletarPainelPorId(id).then((response: AxiosResponse<Response>) => {
          if (response) {
            fetchCsps();
            toast.success("CSP deletado(a) com sucesso!");
          }
        }),
      ]);
    } catch (error) {
      toast.error("Erro ao deletar csp:" + error);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = csps.filter(
    (item) =>
      item.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.usuario.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <Layout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 3,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">CSP</Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  onClick={handleAdd}
                >
                  Adicionar
                </Button>
                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <CogIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  onClick={handleConfig}
                >
                  Configurações
                </Button>
              </Stack>
            </Stack>
            <Search onChange={handleSearchChange} />
            <TableCsp
              items={filteredData}
              onDelete={handleDelete}
              isLoading={isLoading}
            />
          </Stack>
        </Container>
      </Box>
    </Layout>
  );
};

export default HomeCsp;
