import { Refresh as RefreshIcon } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Stack,
  Typography,
  IconButton,
  SxProps,
  Theme,
  CircularProgress,
  Box,
} from "@mui/material";
import { FC } from "react";

interface OverviewServerProps {
  servidor: string;
  url: string;
  porta: string;
  status: string;
  onRefresh: () => void;
  isLoading: boolean;
  sx?: SxProps<Theme>;
}

export const OverviewServer: FC<OverviewServerProps> = ({
  servidor,
  url,
  porta,
  status,
  onRefresh,
  isLoading,
  sx,
}) => {
  const statusColor = status === "Online" ? "green" : "red";

  return (
    <Card sx={sx}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              {"Servidor"}
            </Typography>
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <>
                <Typography variant="body2">
                  <strong>Nome:</strong> {servidor}
                </Typography>
                <Typography variant="body2">
                  <strong>URL:</strong> {url}
                </Typography>
                <Typography variant="body2">
                  <strong>Porta:</strong> {porta}
                </Typography>
                <Typography variant="body2">
                  <strong>Status:</strong> {status}
                  <Box
                    component="span"
                    sx={{
                      display: "inline-block",
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                      backgroundColor: statusColor,
                      marginLeft: 1,
                    }}
                  />
                </Typography>
              </>
            )}
          </Stack>
          <IconButton onClick={onRefresh}>
            <RefreshIcon />
          </IconButton>
        </Stack>
      </CardContent>
    </Card>
  );
};
