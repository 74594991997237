import { Card } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React from "react";

import { ListagemDesconectado } from "../../types";

interface MuiTableProps {
  items: ListagemDesconectado[];
  isLoading: boolean;
}

export const TableDesconectado: React.FC<MuiTableProps> = ({
  items,
  isLoading,
}) => {
  const columns: GridColDef[] = [
    { field: "nome", headerName: "Nome", width: 130 },
    { field: "perfil", headerName: "Perfil", width: 110 },
    { field: "ultimoLogin", headerName: "Último Login", width: 200 },
    { field: "vistoPorUltimo", headerName: "Visto por Último", width: 200 },
    { field: "ip", headerName: "IP", width: 140 },
    { field: "userLog", headerName: "Logs", width: 100 },
  ];

  return (
    <Card>
      <div style={{ height: 550, width: "100%" }}>
        <DataGrid
          rows={items}
          columns={columns}
          pagination
          loading={isLoading}
        />
      </div>
    </Card>
  );
};
