import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import {
  Card,
  InputAdornment,
  OutlinedInput,
  SvgIcon,
  TextField,
  Box,
} from "@mui/material";
import React from "react";

import { HierarquiaUsuario } from "../types";

export const Search: React.FC<{
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  hierarquias?: HierarquiaUsuario[];
  onHierarquiaChange?: (event: React.ChangeEvent<any>) => void;
  handleActionChange?: (event: React.ChangeEvent<any>) => void;
}> = ({
  onChange,
  placeholder = "Pesquisar",
  hierarquias,
  onHierarquiaChange,
  handleActionChange,
}) => (
  <Card sx={{ p: 2, display: "flex", alignItems: "center", gap: 2 }}>
    <OutlinedInput
      defaultValue=""
      fullWidth
      placeholder={placeholder}
      startAdornment={
        <InputAdornment position="start">
          <SvgIcon color="action" fontSize="small">
            <MagnifyingGlassIcon />
          </SvgIcon>
        </InputAdornment>
      }
      sx={{ maxWidth: 500 }}
      onChange={onChange}
    />
    {hierarquias && onHierarquiaChange && (
      <Box sx={{ width: "100%" }}>
        <TextField
          fullWidth
          label="Hierarquia"
          name="hierarquia"
          required
          select
          SelectProps={{ native: true }}
          onChange={onHierarquiaChange}
        >
          <option key={0} value={0}>
            {"Todos"}
          </option>
          {hierarquias.map((hierarquia) => (
            <option key={hierarquia.id} value={hierarquia.id}>
              {hierarquia.hierarquia}
            </option>
          ))}
        </TextField>
      </Box>
    )}
    {handleActionChange && (
      <Box sx={{ width: "100%" }}>
        <TextField
          select
          label="Escolha uma ação"
          fullWidth
          onChange={handleActionChange}
          SelectProps={{ native: true }}
        >
          <option key={0} value=""></option>
          <option key={1} value="alterarVencimento">
            Alterar Vencimento
          </option>
          <option key={2} value="bloquear">
            Bloquear/Desbloquear
          </option>
        </TextField>
      </Box>
    )}
  </Card>
);
