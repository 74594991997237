import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import "dayjs/locale/pt-br";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React from "react";
import { toast } from "react-toastify";

interface AlterarVencimentoModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (newDate: string) => void;
}

const AlterarVencimentoModal: React.FC<AlterarVencimentoModalProps> = ({
  isOpen,
  onClose,
  onSave,
}) => {
  const [newExpiryDate, setNewExpiryDate] = React.useState<dayjs.Dayjs | null>(
    dayjs(),
  );

  const handleClose = (save: boolean) => {
    if (save) {
      if (newExpiryDate && newExpiryDate.isAfter(dayjs(), "day")) {
        onSave(newExpiryDate.format("DD/MM/YYYY"));
      } else {
        toast.error("A data de vencimento deve ser maior que a data atual.");
      }
    } else {
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => handleClose(false)}>
      <DialogTitle>Alterar Vencimento</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
          <DatePicker
            autoFocus
            label="Nova Data de Vencimento"
            minDate={dayjs().add(1, "day")}
            slotProps={{
              textField: {
                required: true,
              },
            }}
            value={newExpiryDate}
            onChange={setNewExpiryDate}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(true)} color="primary">
          Salvar
        </Button>
        <Button onClick={() => handleClose(false)}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlterarVencimentoModal;
