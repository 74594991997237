import type { ListagemAlertaUsuario, AxiosResponse } from "../../types";
import { painelApi } from "../painelApi";

const PATH_V1_ALERTAS = "/v1/alertas";

export async function recuperarTodosAlertas(): Promise<
  AxiosResponse<ListagemAlertaUsuario[]>
> {
  return await painelApi.get(PATH_V1_ALERTAS);
}
