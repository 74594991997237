import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { FC, useState } from "react";
import { toast } from "react-toastify";

import { Layout } from "../../components/layout";
import { downloadBackup } from "../../services/backup";

const HomeBackup: FC = () => {
  const [backupLoading, setBackupLoading] = useState<boolean>(false);

  const handleBackup = async () => {
    setBackupLoading(true);
    try {
      const { fileName, data } = await downloadBackup();
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      toast.success("Backup baixado com sucesso!");
    } catch (error: any) {
      toast.error("Erro ao realizar backup: " + error.message);
    } finally {
      setBackupLoading(false);
    }
  };

  return (
    <Layout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4">Backup do Banco de Dados</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={handleBackup}
                disabled={backupLoading}
              >
                {backupLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  "Download Arquivo"
                )}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Layout>
  );
};

export default HomeBackup;
