import type {
  ListagemUsuario,
  FiltroUsuario,
  AxiosResponse,
  Usuario,
  Response,
  Me,
  HierarquiaUsuario,
  StatusCount,
} from "../../types";
import { painelApi } from "../painelApi";

const PATH_V1_USUARIO = "/v1/usuario";

export async function recuperarTodosUsuariosHierarquia(
  filtros?: FiltroUsuario,
): Promise<AxiosResponse<ListagemUsuario[]>> {
  if (filtros) {
    return await painelApi.get(`${PATH_V1_USUARIO}/filtrar`, {
      params: filtros,
    });
  }
  return await painelApi.get(PATH_V1_USUARIO);
}

export async function recuperarTodosUsuariosDiretos(
  filtros?: FiltroUsuario,
): Promise<AxiosResponse<ListagemUsuario[]>> {
  if (filtros) {
    return await painelApi.get(`${PATH_V1_USUARIO}/diretos`, {
      params: filtros,
    });
  }
  return await painelApi.get(PATH_V1_USUARIO);
}

export async function salvarUsuario(
  request: Usuario,
): Promise<AxiosResponse<Response>> {
  return await painelApi.post(PATH_V1_USUARIO, request);
}

export async function recuperarUsuarioPorId(
  codigo: string,
): Promise<AxiosResponse<Usuario>> {
  return await painelApi.get(`${PATH_V1_USUARIO}/${codigo}`);
}

export async function atualizarUsuarioPorId(
  codigo: string,
  request: Usuario,
): Promise<AxiosResponse<Response>> {
  return await painelApi.put(`${PATH_V1_USUARIO}/${codigo}`, request);
}

export async function atualizarListaDeUsuarios(
  request: ListagemUsuario[],
): Promise<AxiosResponse<Response>> {
  return await painelApi.put(`${PATH_V1_USUARIO}/editar-lista`, request);
}

export async function bloquearListaDeUsuariosHierarquia(
  request: ListagemUsuario[],
): Promise<AxiosResponse<Response>> {
  return await painelApi.put(`${PATH_V1_USUARIO}/bloquear`, request);
}

export async function deletarUsuarioPorId(
  codigo: string,
): Promise<AxiosResponse<Response>> {
  return await painelApi.delete(`${PATH_V1_USUARIO}/${codigo}`);
}

export async function getMe(userToken: string): Promise<AxiosResponse<Me>> {
  return await painelApi.post("/v1/me", JSON.stringify({ token: userToken }));
}

export async function recuperarHierarquiaPorId(
  codigo: string,
  tipoUsuario?: string | null,
): Promise<AxiosResponse<HierarquiaUsuario[]>> {
  return await painelApi.get(`${PATH_V1_USUARIO}/hierarquia/${codigo}`, {
    params: {
      tipoUsuario,
    },
  });
}

export async function recuperarStatus(
  codigo: string,
): Promise<AxiosResponse<StatusCount>> {
  return await painelApi.get(`${PATH_V1_USUARIO}/status-count/${codigo}`);
}
