import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import React, { FC, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Layout } from "../../components/layout";
import {
  recuperarPainelPorId,
  salvarPainel,
  atualizarPainelPorId,
} from "../../services/csp";
import { Painel, AxiosResponse } from "../../types";

const TelaCsp: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [painel, setPainel] = useState<Painel>({
    id: null,
    nome: "",
    url: "",
    porta: "",
    usuario: "",
    senha: "",
    protocolo: "",
    bloqueado: "N",
  });

  let title = "";

  if (id) {
    title = "Editar";
  } else {
    title = "Cadastrar";
  }

  useEffect(() => {
    if (id) {
      Promise.all([
        recuperarPainelPorId(id).then((response: AxiosResponse<Painel>) => {
          if (response) {
            setPainel(response.data);
          }
        }),
      ]);
    }
  }, [id]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (id) {
        atualizarPainelPorId(id, painel).then((response) => {
          if (response) {
            toast.success("CSP atualizada com sucesso!");
            navigate(-1);
          }
        });
      } else {
        salvarPainel(painel).then((response) => {
          if (response) {
            toast.success("CSP salva com sucesso!");
            navigate(-1);
          }
        });
      }
    },
    [painel],
  );

  const handleCancelar = () => {
    navigate(-1);
  };

  return (
    <Layout>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card>
          <CardHeader
            subheader="Essas informações podem ser editadas"
            title={title}
          />
          <CardContent sx={{ pt: 0 }}>
            <Box sx={{ m: -1.5 }}>
              <Grid container spacing={3}>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Nome"
                    name="nome"
                    required
                    value={painel.nome}
                    onChange={(event) =>
                      setPainel({ ...painel, nome: event.target.value })
                    }
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="URL"
                    name="url"
                    required
                    value={painel.url}
                    onChange={(event) =>
                      setPainel({ ...painel, url: event.target.value })
                    }
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Porta"
                    name="porta"
                    type="number"
                    required
                    value={painel.porta}
                    onChange={(event) =>
                      setPainel({ ...painel, porta: event.target.value })
                    }
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Usuário"
                    name="usuario"
                    required
                    value={painel.usuario}
                    onChange={(event) =>
                      setPainel({ ...painel, usuario: event.target.value })
                    }
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Senha"
                    name="senha"
                    required
                    value={painel.senha}
                    onChange={(event) =>
                      setPainel({ ...painel, senha: event.target.value })
                    }
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Protocolo"
                    name="protocolo"
                    required
                    value={painel.protocolo}
                    onChange={(event) =>
                      setPainel({ ...painel, protocolo: event.target.value })
                    }
                  />
                </Grid>
                <Grid xs={12} md={2}>
                  <TextField
                    fullWidth
                    label="Bloqueado"
                    name="bloqueado"
                    required
                    select
                    SelectProps={{ native: true }}
                    value={painel.bloqueado}
                    onChange={(event) =>
                      setPainel({ ...painel, bloqueado: event.target.value })
                    }
                  >
                    <option value="S">Sim</option>
                    <option value="N">Não</option>
                  </TextField>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button type="submit" variant="contained">
              Salvar
            </Button>
            <Button type="button" variant="outlined" onClick={handleCancelar}>
              Cancelar
            </Button>
          </CardActions>
        </Card>
      </form>
    </Layout>
  );
};

export default TelaCsp;
