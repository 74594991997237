import {
  Box,
  Divider,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from "@mui/material";
import React, { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "../hooks/useAuth";

interface AccountPopoverProps {
  anchorEl: null | HTMLElement;
  onClose: () => void;
  open: boolean;
}

export const AccountPopover: FC<AccountPopoverProps> = ({
  anchorEl,
  onClose,
  open,
}) => {
  const navigate = useNavigate();
  const { signout, user } = useAuth();

  const handleSignOut = useCallback(() => {
    onClose?.();
    signout();
    navigate("/");
  }, [onClose, signout, navigate]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 200 } }}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2,
        }}
      >
        <Typography variant="overline">Conta</Typography>
        <Typography color="text.secondary" variant="body2">
          {user?.nome}
        </Typography>
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: "8px",
          "& > *": {
            borderRadius: 1,
          },
        }}
      >
        <MenuItem onClick={handleSignOut}>Sair</MenuItem>
      </MenuList>
    </Popover>
  );
};
