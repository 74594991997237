import type { AxiosResponse } from "../../types";
import { painelApi } from "../painelApi";

const PATH_V1_EXPORT = "/v1/import/export";

export async function exportData(): Promise<AxiosResponse<Blob>> {
  return painelApi.get(PATH_V1_EXPORT, {
    responseType: "blob",
    headers: {
      "Content-Disposition": 'attachment; filename="data.sql"',
    },
  });
}
