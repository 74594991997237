import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";
import dayjs from "dayjs";
import React, { FC, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Layout } from "../../components/layout";
import useAuth from "../../hooks/useAuth";
import { recuperarTodosAlertas } from "../../services/alertaUsuario";
import { recuperarTodosPerfis } from "../../services/perfil";
import {
  recuperarUsuarioPorId,
  salvarUsuario,
  atualizarUsuarioPorId,
} from "../../services/usuario";
import {
  Usuario,
  ListagemAlertaUsuario,
  ListagemPerfil,
  AxiosResponse,
  Response,
} from "../../types";

const TelaUsuarioRapido: FC = () => {
  const navigate = useNavigate();
  dayjs.locale("pt-br");
  const { user, editUser, signout, setEditUser } = useAuth();
  const [alertasUsuario, setAlertasUsuario] =
    useState<ListagemAlertaUsuario[]>();
  const [perfis, setPerfis] = useState<ListagemPerfil[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [usuario, setUsuario] = useState<Usuario>({
    id: null,
    tipoUsuario: editUser ? editUser.tipo : "usuario",
    alertaId: "1",
    perfilId: "1",
    cadastradoPor: "",
    usuario: "",
    nome: "",
    senha: "",
    email: "",
    celular: "",
    dataVencimento:
      editUser?.tipo === "TESTE"
        ? dayjs().add(2, "day").format("DD/MM/YYYY")
        : "",
    dataNascimento: "",
    conexao: 1,
    valorCobrado: "",
    prePago: "N",
    xml: "S",
    mensagemInterna: "",
    obs: "",
    limiteTeste: "",
    limiteUser: "",
    bloqueado: "N",
  });

  let title = "";

  if (editUser?.idUsuario) {
    title = "Editar";
  } else {
    title = "Cadastrar";
  }

  useEffect(() => {
    if (user?.idUsuario) {
      Promise.all([
        recuperarTodosAlertas()
          .then((response: AxiosResponse<ListagemAlertaUsuario[]>) => {
            if (response) {
              setAlertasUsuario(response.data);
            }
          })
          .catch((error: any) => {
            if (error.response && error.response.status === 401) {
              toast.info("Desconectado por inatividade");
              signout();
              navigate("/");
            } else {
              toast.error("Erro ao recuperar alertas: " + error.message);
            }
          }),
        recuperarTodosPerfis()
          .then((response: AxiosResponse<ListagemPerfil[]>) => {
            if (response) {
              setPerfis(response.data);
            }
          })
          .catch((error: any) => {
            if (error.response && error.response.status === 401) {
              toast.info("Desconectado por inatividade");
              signout();
              navigate("/");
            } else {
              toast.error("Erro ao recuperar perfis: " + error.message);
            }
          }),
      ]);
    }
  }, [user]);

  useEffect(() => {
    if (editUser?.idUsuario) {
      Promise.all([
        recuperarUsuarioPorId(editUser.idUsuario)
          .then((response: AxiosResponse<Usuario>) => {
            if (response) {
              let fetchedUser = response.data;
              if (
                fetchedUser.tipoUsuario === "ADMIN" ||
                fetchedUser.tipoUsuario === "REVENDA"
              ) {
                fetchedUser = { ...fetchedUser, senha: "" };
              }
              setUsuario(fetchedUser);
            }
          })
          .catch((error: any) => {
            if (error.response && error.response.status === 401) {
              toast.info("Desconectado por inatividade");
              signout();
              navigate("/");
            } else {
              toast.error("Erro ao recuperar o usuário:" + error.message);
            }
          }),
      ]);
    }
  }, [editUser]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setIsLoading(true);

      if (!user || !user.idUsuario) {
        toast.error(
          "Erro: Usuário não está autenticado ou ID do usuário não disponível.",
        );
        setIsLoading(false);
        return;
      }

      if (usuario.tipoUsuario === "TESTE") {
        const dataEsperada = dayjs().startOf("day").add(2, "day");

        if (!dayjs(usuario.dataVencimento, "DD/MM/YYYY").isSame(dataEsperada)) {
          toast.error(
            "A data de vencimento para TESTE deve ser exatamente 2 dias a partir de hoje.",
          );
          setIsLoading(false);
          return;
        }
      }

      if (
        usuario.dataVencimento &&
        dayjs(usuario.dataVencimento, "DD/MM/YYYY")
          .startOf("day")
          .isBefore(dayjs().startOf("day").add(1, "day"))
      ) {
        toast.error("A data de vencimento deve ser maior que hoje.");
        setIsLoading(false);
        return;
      }

      const usuarioParaSalvar: Usuario = {
        ...usuario,
        cadastradoPor: user!.idUsuario,
      };

      if (editUser?.idUsuario) {
        const usuarioparaEditar: Usuario = {
          ...usuario,
        };

        atualizarUsuarioPorId(editUser.idUsuario, usuarioparaEditar)
          .then((response: AxiosResponse<Response>) => {
            if (response) {
              toast.success("Usuário atualizado com sucesso!");
              setEditUser(null);
              navigate(-1);
            }
          })
          .catch((error) => {
            let errorMessage = "";
            if (
              error.response &&
              error.response.data &&
              error.response.data.response
            ) {
              errorMessage += error.response.data.response;
            } else {
              errorMessage += error.message;
            }
            toast.error(errorMessage);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        salvarUsuario(usuarioParaSalvar)
          .then((response: AxiosResponse<Response>) => {
            if (response) {
              toast.success("Usuário salvo com sucesso!");
              setEditUser(null);
              navigate(-1);
            }
          })
          .catch((error) => {
            let errorMessage = "";
            if (
              error.response &&
              error.response.data &&
              error.response.data.response
            ) {
              errorMessage += error.response.data.response;
            } else {
              errorMessage += error.message;
            }
            toast.error(errorMessage);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [usuario],
  );

  const handleChangeDataVencimento = (date: string | null) => {
    if (date !== null) {
      setUsuario({
        ...usuario,
        dataVencimento: date,
      });
    }
  };

  const handleCancelar = () => {
    setEditUser(null);
    navigate(-1);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <Layout>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              subheader="Essas informações podem ser editadas"
              title={title}
            />
            <CardContent sx={{ pt: 0 }}>
              <Box sx={{ m: -1.5 }}>
                <Grid container spacing={3}>
                  <Grid xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Nome"
                      name="nome"
                      required
                      value={usuario.nome}
                      onChange={(event) =>
                        setUsuario({ ...usuario, nome: event.target.value })
                      }
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Usuário"
                      name="usuario"
                      required
                      value={usuario.usuario}
                      onChange={(event) =>
                        setUsuario({ ...usuario, usuario: event.target.value })
                      }
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Senha"
                      name="senha"
                      required={
                        !editUser ||
                        editUser.idUsuario === null ||
                        (editUser.idUsuario !== null &&
                          editUser.tipo !== "ADMIN" &&
                          editUser.tipo !== "REVENDA")
                      }
                      value={usuario.senha}
                      onChange={(event) =>
                        setUsuario({ ...usuario, senha: event.target.value })
                      }
                    />
                  </Grid>
                  <Grid xs={12} md={3}>
                    <DatePicker
                      label="Vencimento"
                      minDate={dayjs().add(1, "day")}
                      slotProps={{
                        textField: {
                          required: true,
                        },
                      }}
                      value={
                        usuario.dataVencimento
                          ? dayjs(usuario.dataVencimento, "DD/MM/YYYY")
                          : null
                      }
                      onChange={(date) =>
                        handleChangeDataVencimento(
                          date ? dayjs(date).format("DD/MM/YYYY") : null,
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
            <Divider />
            <CardActions sx={{ justifyContent: "flex-end" }}>
              <Button type="submit" variant="contained" disabled={isLoading}>
                {isLoading ? <CircularProgress size={24} /> : "Salvar"}
              </Button>
              <Button
                type="button"
                variant="outlined"
                onClick={handleCancelar}
                disabled={isLoading}
              >
                Cancelar
              </Button>
            </CardActions>
          </Card>
        </form>
      </Layout>
    </LocalizationProvider>
  );
};

export default TelaUsuarioRapido;
